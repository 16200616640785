import React from 'react';
import PropTypes from 'prop-types';

function Accordion(props) {
  const {
    label,
    activeAccordion,
    onClick,
    data,
    icon,
  } = props;

  const handleClick = () => {
    onClick(label);
  };
  let className = 'section';

  if (activeAccordion === label) {
    className += ' section-active';
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={className}
      onClick={handleClick}
      onKeyUp={() => {}}
    >

      <div className="section-header">
        {icon}
        <h3>
          {label}
        </h3>
      </div>
      <div className="section-content">{data}</div>
    </li>
  );
}

export default Accordion;

Accordion.propTypes = {
  label: PropTypes.string.isRequired,
  activeAccordion: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  icon: PropTypes.object.isRequired,
};
