import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FiSave,
} from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
// import { areasUpdate } from '../../app/areas';

//
const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

function AreaAdd() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const areas = useSelector((state) => state.areas.list);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const createArea = async (data) => {
    console.log(data);
    const response = await client.post(
      '/web',
      data,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    dispatch([response.data, ...areas]);
    setSubmitting(false);
  };

  function onSubmit(data) {
    setSubmitting(true);
    createArea(data);
  }

  return (
    <div>
      <h2>Create a new staging area</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="notes">
          <span>Name:</span>
          <input
            placeholder="name"
            name="name"
            id="name"
            type="text"
            {...register('name', { required: true })}
          />
          {errors.notes && <p>This field is required</p>}
        </label>
        <label htmlFor="notes" className="align-top">
          <span>Notes:</span>
          <textarea
            placeholder="notes"
            name="notes"
            id="notes"
            {...register('notes', { required: true })}
          />
          {errors.notes && <p>This field is required</p>}
        </label>
        <label className="check" htmlFor="is_support">
          <span>Support & Service:</span>
          <input
            name="is_support"
            id="is_support"
            type="checkbox"
            {...register('is_support')}
          />
          <div className="checkmark" />
        </label>
        <label className="check" htmlFor="is_cors">
          <span>Enable CORS:</span>
          <input
            name="is_cors"
            id="is_cors"
            type="checkbox"
            {...register('is_cors')}
          />
          <div className="checkmark" />
        </label>
        <label className="check" htmlFor="is_private">
          <span>Make private</span>
          <input
            name="is_private"
            id="is_private"
            type="checkbox"
            {...register('is_private')}
          />
          <div className="checkmark" />
        </label>
        <label className="check" htmlFor="is_protected">
          <span>Protected:</span>
          <input
            name="is_protected"
            id="is_protected"
            type="checkbox"
            {...register('is_protected')}
          />
          <div className="checkmark" />
        </label>
        <label className="check" htmlFor="is_public">
          <span>Public:</span>
          <input
            name="is_public"
            id="is_public"
            type="checkbox"
            {...register('is_public')}
          />
          <div className="checkmark" />
        </label>
        <div className="spacer">
          <span> </span>
          <button type="submit">
            <FiSave />
            {' '}
            CREATE
          </button>
          {
            submitting
              && <p>Submiting form... please wait</p>
          }
        </div>
      </form>
    </div>
  );
}

export default AreaAdd;
