import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePermissions } from '../Helpers/Helpers';

import './AddEditViewArea.scss';
import AreaView from './AreaView';
import AreaEdit from './AreaEdit';
import AreaAdd from './AreaAdd';

function AddEditViewArea(props) {
  const {
    area,
  } = props;

  const { id } = useParams();
  const settings = useSelector((state) => state.settings);

  console.log(id);
  // only way that AreaAaa is called
  const isAddMode = (id === 'new');
  // checking conditions for view only
  const isEditMode = (usePermissions('edit-webs') && settings.user.id === area.user_id) ? !area.is_backup : false;
  // View if above are false
  const isViewMode = !isAddMode && !isEditMode;

  return (
    <div className="area-edit">
      {
        isAddMode
        && <AreaAdd area={area} />
      }
      {isEditMode
        && (
        <AreaEdit area={area} />
        )}
      {isViewMode
        && (
          <AreaView area={area} />
        )}
    </div>
  );
}

export default AddEditViewArea;

AddEditViewArea.defaultProps = {
  area: {},
};

AddEditViewArea.propTypes = {
  area: PropTypes.object,
};
