import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const areaSlice = createSlice({
  name: 'areas',
  initialState: {
    list: [],
    total: 0,
    loading: false,
  },
  reducers: {
    areasRequested: (areas) => {
      areas.loading = true;
      console.log('Requesting data');
    },

    areasReceived: (areas, action) => {
      // handle and clear object here
      areas.list = action.payload;
      areas.total = action.payload.length;
      areas.loading = false;
      console.log('Data received and handled!');
    },

    areasRequestFailed: (areas) => {
      areas.loading = false;
      console.log('Request failed!');
    },

    areasUpdate: (areas, action) => {
      areas.list = action.payload;
    },
  },
});

export default areaSlice.reducer;

export const {
  areasRequested,
  areasReceived,
  areasRequestFailed,
  areasUpdate,
} = areaSlice.actions;

export const loadAreas = () => (dispatch) => dispatch(
  apiCallBegan({
    url: '/web',
    method: 'get',
    params: {},
    onStart: areasRequested.type,
    onSuccess: areasReceived.type,
    onError: areasRequestFailed.type,
  }),
);
