import { useLocation, useParams } from 'react-router-dom';

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ''),
    location.pathname,
  );
};

export const usePermissions = (test) => {
  const getPermissions = () => {
    const userString = sessionStorage.getItem('user');
    const userPerms = JSON.parse(userString);
    return userPerms?.scopes.some((perm) => perm.scope === test);
  };

  return getPermissions();
};
