import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  FiLogIn,
} from 'react-icons/fi';
import './Login.scss';

const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

async function loginUser(credentials) {
  return client.post('/auth/login', credentials).then((response) => response.data);
}

function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const token = await loginUser({
      email,
      password,
    });
    setSubmitting(false);
    setToken(token);
  };

  return (
    <div className="panel-wrapper">
      {submitting
            && <div>Logging in... Please wait...</div>}
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          <span>Username:</span>
          <input type="text" onChange={(e) => setEmail(e.target.value)} id="email" />
        </label>
        <label htmlFor="password">
          <span>Password:</span>
          <input type="password" onChange={(e) => setPassword(e.target.value)} id="password" />
        </label>
        <div className="spacer">
          <span> </span>
          <Link to="/forgot-password" id="link">Forgot password</Link>
        </div>
        <div className="spacer">
          <span> </span>
          <button type="submit">
            <FiLogIn />
            {' '}
            LOGIN
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
