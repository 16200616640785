import React from 'react';
import { useBasePath } from '../Helpers/Helpers';

import './Dashboard.scss';

// BLOCKS REQUIRED
import ListAreas from '../Blocks/ListAreas';
import ListUsers from '../Blocks/ListUsers';
import EditProfile from '../Blocks/EditProfile';
import EditPreferences from '../Blocks/EditPreferences';

function Dashboard() {
  const basePath = useBasePath();
  // console.log(basePath);

  return (
    <div className="panel-wrapper">
      {(() => {
        switch (basePath) {
          case '/':
            return <ListAreas />;
          case '/profile':
            return <EditProfile />;
          case '/preferences':
            return <EditPreferences />;
          case '/users':
            return <ListUsers />;
          case '/areas':
            return <ListAreas />;
          default:
            return <ListAreas />;
        }
      })()}
    </div>
  );
}

export default Dashboard;
