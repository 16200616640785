import axios from 'axios';
import * as actions from '../api';

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const {
    url, method, onStart, onSuccess, onError,
  } = action.payload;

  if (onStart) dispatch({ type: onStart });

  next(action);

  try {
    // TODO: add token to settings' store
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    const response = await axios.request({
      baseURL: 'https://api.web.headspin.no/api',
      url,
      method,
      headers: { Authorization: `Bearer ${userToken.token}` },
    });
    // General
    dispatch(actions.apiCallSuccess(response.data));
    // Specific
    if (onSuccess) {
      dispatch({ type: onSuccess, payload: response.data });
    }
  } catch (error) {
    // General
    dispatch(actions.apiCallFailed(error.message));
    // Specific
    if (onError) dispatch({ type: onError, payload: error.message });
  }
  return true;
};

export default api;
