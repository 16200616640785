import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter, Route, Routes, Navigate, Link,
} from 'react-router-dom';
import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import useToken from '../Helpers/useToken';
import MainMenu from '../Blocks/MainMenu';
import { usePermissions } from '../Helpers/Helpers';
// redux
import { selectSettings, updateSettings } from '../../app/settings';
import { loadAreas } from '../../app/areas';
import { loadUsers } from '../../app/users';

import './App.scss';

function App() {
  const dispatch = useDispatch();
  const { token, setToken } = useToken();
  const settings = useSelector(selectSettings);
  const canLoadUsers = usePermissions('view-users');

  useEffect(() => {
    if (token) {
      dispatch(updateSettings({
        ...settings,
        ...{
          token: JSON.parse(sessionStorage.getItem('token')).token,
          user: JSON.parse(sessionStorage.getItem('user')),
        },
      }));
      dispatch(loadAreas());
      if (canLoadUsers) dispatch(loadUsers());
    }
  }, [canLoadUsers, dispatch, token]);

  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <div className="header">
          <div className="left-col">
            <Link to="./">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2350.2 505.8">
                <path className="filler" d="M386.7 84.4L278 .6c-1.3-1-3.1-.8-4 .5-.4.5-.6 1.1-.6 1.8v500c0 1.6 1.3 2.9 2.9 2.9.6 0 1.3-.2 1.8-.6l108.7-83.9c1.6-1.2 2.6-3.2 2.6-5.2V89.6c-.2-2-1.1-3.9-2.7-5.2M258.2 209.8H118.7c-2 0-3.6-1.6-3.6-3.6V2.9c0-1.6-1.3-2.9-2.9-2.9-.6 0-1.3.2-1.8.6L2.6 84C1 85.3 0 87.2 0 89.2v327.3c0 2 .9 4 2.5 5.2l107.9 83.4c1.3 1 3.1.7 4-.5.4-.5.6-1.1.6-1.8V331.5c0-1.8.8-3.5 2.3-4.6l143.1-110.6c1.6-1.2 1.9-3.5.7-5.1-.7-.9-1.7-1.4-2.9-1.4" />
                <path className="filler text" d="M626.3 157.2h45.9v75.1H757v-75.1h45.9V347H757v-76.1h-84.8V347h-45.9zM926.2 270h73.4v-37.1h-73.4v-38.5h111.9v-37.2H880.5v189.9h159.1v-37.2H926.2zM1166.8 267.6l27.8-61.6 27.7 61.6h-55.5zm7.2-110.3l-89.5 189.8h46.8l19.1-42.6h88.3l19.1 42.6h48l-89.5-189.8H1174zM1501.6 252.7c0 33.6-25.4 56.7-62.9 56.7h-35.5V194.9h35.5c37.6 0 62.9 23.6 62.9 57.2v.6zm-62.9-95.4h-81.4v189.8h81.4c65.6 0 111-41.5 111-94.9v-.5c0-53.5-45.4-94.5-111-94.4M1937.2 224.3c0 16.3-13.4 28.8-36.4 28.8h-36.4v-58h35.5c23 0 37.3 10 37.3 28.7v.5zm-33.4-67h-85.3v189.8h46v-57h34.9c46.9 0 84.4-22.8 84.4-66.7v-.5c0-38.9-30.2-65.7-80-65.6M2041 157.2h45.9V347H2041zM2304.9 157.2v102.3l-142.8-110.7v198.3h45.3V242.8l142.8 111.8V157.2zM1684.9 234c-45.2 0-45.4-15.9-45.3-24.9.2-12.6 12.5-20.3 30.2-20.3 15.1-.2 30.1 1.6 44.7 5.5l34.4-26.5c-26.5-10.7-53.8-13.9-78.8-13.9-42.8 0-73.3 22.8-73.3 57.3v.6c1.6 29.6 23.7 54.3 69.4 57.1 52.5 2 44.5 27.7 44.5 27.7-1.3 6.7-4.6 17.8-34.1 17.7-18.7 0-37.4-2.8-55.3-8.2l-34.3 26.4c28.8 12.1 63 17.2 90.2 17.2 45.2 0 76.9-21.2 76.9-59v-.7c-1.4-26.4-23.4-54.2-69.2-56" />
              </svg>
            </Link>
          </div>
          <h1 className="title">Web Staging Server</h1>
        </div>
        <div className="mainpanel">
          <div className="left-col">
            { token && <MainMenu />}
          </div>
          { (!token)
        && (
          <Routes>
            <Route path="/" element={<Login setToken={setToken} />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
          { (token)
        && (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/areas" element={<Dashboard />} />
            <Route path="/areas/:id" element={<Dashboard />} />
            <Route path="/users" element={<Dashboard />} />
            <Route path="/users/:id" element={<Dashboard />} />
            <Route path="/profile" element={<Dashboard />} />
            <Route path="/preferences" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
