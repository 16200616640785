import React from 'react';
import PropTypes from 'prop-types';

function Tab(props) {
  const {
    label,
    activeTab,
    onClick,
  } = props;

  const handleClick = () => {
    onClick(label);
  };
  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={className}
      onClick={handleClick}
      onKeyUp={() => {}}
    >
      {label}
    </li>
  );
}

export default Tab;

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
