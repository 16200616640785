import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  FiTrash2,
  FiEdit,
  FiPlus,
} from 'react-icons/fi';
import { usePermissions } from '../Helpers/Helpers';
import AddEditUser from './AddEditUser';
import { selectSettings } from '../../app/settings';
import { usersUpdate } from '../../app/users';

import './ListUsers.scss';

//
const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

function ListUsers() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const users = useSelector((state) => state.users.list);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  // DELETE with Axios
  const deleteUser = async (usrId) => {
    setSubmitting(true);
    const response = await client.delete(
      `/users/${usrId}`,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    const cleanUsers = users.filter((user) => user.id !== response.data.user.id);
    dispatch(usersUpdate(cleanUsers));
    setSubmitting(false);
  };

  const handleDelete = (event) => {
    const { target } = event;
    deleteUser(target.id);
  };

  const handleEdit = (event) => {
    const { target } = event;
    console.log(target);
    navigate(`../users/${target.id}`, { replace: true });
  };

  const self = JSON.parse(sessionStorage.getItem('user'));
  const edit = usePermissions('delete-users');

  return (
    <div className="users-container">
      {
            submitting
            && <span>DELETING</span>
        }
      <div className="panel-header">
        <h2>List of Users</h2>
        <button type="button" onClick={handleEdit} id="new">
          <FiPlus />
          {' '}
          <span className="label">ADD NEW</span>
        </button>
      </div>
      <div className="holder">
        <div className="list">
          {
            users.map((user) => (
              self.id !== user.id
                && (
                <div className="list-card" key={user.id}>
                  <div className="list-title">{user.name}</div>
                  <div className="buttons">
                    <button className="icon-only" type="button" onClick={handleEdit} id={user.id}>
                      <FiEdit />
                      {' '}
                      <span className="label">EDIT</span>
                    </button>
                    {
                      edit
                        && (
                        <button className="icon-only" type="button" onClick={handleDelete} id={user.id}>
                          <FiTrash2 />
                          {' '}
                          <span className="label">DELETE</span>
                        </button>
                        )
                    }
                  </div>
                </div>
                )
            ))
          }
        </div>
        {
          (usePermissions('edit-users') && id && users.length > 0)
            && <AddEditUser user={users.find((item) => item.id === parseInt(id, 10))} />
        }
      </div>
    </div>
  );
}

export default ListUsers;
