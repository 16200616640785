import React from 'react';
import {
  FiArchive,
  FiLock,
  FiFolder,
  FiLayers,
  FiDatabase,
  FiShield,
  FiGitlab,
} from 'react-icons/fi';
import PropTypes from 'prop-types';
import AccordionGroup from './AccordionGroup';

function AreaView(props) {
  const {
    area,
  } = props;

  return (
    <div>
      <h2>View Area</h2>
      <AccordionGroup>
        <div label="Web Area Details" icon={<FiArchive />}>
          <p>
            Area name:
            {' '}
            {area.name}
          </p>
          <p>
            Notes:
            {' '}
            {area.notes}
          </p>
          <p>
            Support & Service:
            {area.is_support ? 'Yes' : 'No'}
          </p>
          <p>
            Owner:
            {area.updated_at}
          </p>
          <p>
            Created:
            {area.created_at}
          </p>
          <p>
            Updated:
            {area.updated_at}
          </p>
        </div>
        <div label="Access Credentials" icon={<FiLock />}>
          <p>
            Username:
            {' '}
            {area.name}
          </p>
          <p>
            Password:
            {' '}
            {area.code}
          </p>
        </div>
        <div label="Database Access" icon={<FiDatabase />}>
          <p>
            DB Host: 172.16.6.33
          </p>
          <p>
            DB Name:
            {' '}
            {area.name}
          </p>

        </div>
        <div label="File System Access" icon={<FiFolder />}>
          <p>Direct file access should be avoided.</p>
          <em>
            Access is exclusive via SFTP (SSH File Transfer Protocol)
          </em>
          <p>
            SFTP Host: web.headspin.no
          </p>
        </div>
        <div label="Aliases" icon={<FiLayers />}>
          {
            (area.alias.length > 0)
              ? area.alias.map((alias) => (
                <p>
                  https://
                  {alias.name}
                  .web.headspin.no
                </p>
              )) : (
                <p>No aliases found</p>
              )
          }
          <span>&nbsp;</span>
        </div>
        <div label="Security" icon={<FiShield />}>
          <p>
            CORS enabled:
            {' '}
            {area.is_cors ? 'Yes' : 'No'}
          </p>
          <p>
            Is private:
            {' '}
            {area.is_private ? 'Yes' : 'No'}
          </p>
          <p>
            Is protected:
            {' '}
            {area.is_protected ? 'Yes' : 'No'}
          </p>
          <p>
            Is public:
            {' '}
            {area.is_public ? 'Yes' : 'No'}
          </p>
          {
            area.access.length > 0
            && (
            <div>
              <p>Available HTTP credentials:</p>
              <ul className="credentials">
                <li>
                  <span>Username</span>
                  <span>Password</span>
                </li>
                {
              area.access.map((access) => (
                <li>
                  <span>{access.username}</span>
                  <span>{access.password}</span>
                </li>
              ))
            }
              </ul>
            </div>
            )
          }
        </div>
        <div label="Version Control" icon={<FiGitlab />}>
          <p>
            Git Hook:
            {' '}
            {area.git_url ? `https://${area.name}.web.headspin.no/git-pull.php` : 'No'}
          </p>
          <span>&nbsp;</span>
        </div>
      </AccordionGroup>
    </div>
  );
}
export default AreaView;

AreaView.propTypes = {
  area: PropTypes.object.isRequired,
};
