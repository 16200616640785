import { configureStore } from '@reduxjs/toolkit';
import areaSlice from './areas';
import userSlice from './users';
import settingsSlice from './settings';
import api from './middleware/api';

export default function store() {
  return configureStore({
    reducer: {
      settings: settingsSlice,
      users: userSlice,
      areas: areaSlice,
    },
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api],
  });
}
