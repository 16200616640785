import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  FiTrash2,
  FiEdit,
  FiPlus,
  FiDownloadCloud,
  FiCloud,
  FiEye,
  FiGlobe,
} from 'react-icons/fi';
import { usePermissions } from '../Helpers/Helpers';
import Tabs from './Tabs';
import AddEditViewArea from './AddEditViewArea';
import { areasUpdate } from '../../app/areas';

import './ListAreas.scss';

//
const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

function ListAreas() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const areas = useSelector((state) => state.areas.list);
  const [submitting, setSubmitting] = useState(false);

  let mine = [];
  let other = [];

  areas.map((area) => {
    if (area.user_id === settings.user.id) {
      mine = [...mine, area];
    } else {
      other = [...other, area];
    }
    return true;
  });

  // DELETE with Axios
  const deleteUser = async (areaId) => {
    setSubmitting(true);
    const response = await client.delete(
      `/web/${areaId}`,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    const cleanAreas = areas.filter((area) => area.id !== response.data.area.id);
    dispatch(areasUpdate(cleanAreas));
    setSubmitting(false);
  };

  const handleDelete = (event) => {
    const { target } = event;
    deleteUser(target.id);
  };

  const handleEdit = (event) => {
    const { target } = event;
    navigate(`../areas/${target.id}`);
  };

  const handleNav = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleRestore = (event) => {
    const { target } = event;
    console.log(`Will restore ${target.id}`);
    target.disabled = 'true';
  };
  const handleArchive = (event) => {
    const { target } = event;
    console.log(`Will archive ${target.id}`);
    target.disabled = 'true';
  };

  return (
    <div className="areas-container">
      {
          submitting
          && <span>DELETING</span>
      }
      <div className="panel-header">
        <h2>Web Areas</h2>
        <button type="button" onClick={handleEdit} id="new">
          <FiPlus />
          {' '}
          ADD NEW
        </button>
      </div>
      <div className="holder">
        <Tabs>
          <div label="Your sites">
            {
                mine.map((area) => (
                  <div className={`list-card ${area.is_backup ? 'backup' : ''}  ${area.is_support ? 'support' : ''} `} key={area.id}>
                    <div className="name">
                      { !area.is_backup
                        && (
                        <button className="icon-only" type="button" onClick={() => handleNav(`https://${area.name}.web.headspin.no`)} id={area.id}>
                          <FiGlobe />
                          {' '}
                          <span className="label">{area.name}</span>
                        </button>
                        )}
                      {' '}
                      {area.name}
                    </div>
                    {
                      area.is_backup
                        ? (
                          <div className="buttons">
                            <button className="icon-only" type="button" onClick={handleEdit} id={area.id}>
                              <FiEye />
                              {' '}
                              <span className="label">VIEW</span>
                            </button>
                            <button className="icon-only" type="button" onClick={handleRestore} id={area.id}>
                              <FiDownloadCloud />
                              {' '}
                              <span className="label">RESTORE</span>
                            </button>
                          </div>
                        )
                        : (
                          <div className="buttons">
                            <button className="icon-only" type="button" onClick={handleEdit} id={area.id}>
                              <FiEdit />
                              {' '}
                              <span className="label">EDIT</span>
                            </button>
                            <button className="icon-only" type="button" onClick={handleArchive} id={area.id}>
                              <FiCloud />
                              {' '}
                              <span className="label">ARCHIVE</span>
                            </button>
                            <button className="icon-only" type="button" onClick={handleDelete} id={area.id}>
                              <FiTrash2 />
                              {' '}
                              <span className="label">DELETE</span>
                            </button>
                          </div>
                        )
                      }
                  </div>
                ))
              }
          </div>
          <div label="Other sites">
            {
                other.map((area) => (
                  <div className={`list-card ${area.is_backup ? 'backup' : ''}  ${area.is_support ? 'support' : ''} `} key={area.id}>
                    <div className="name">
                      { !area.is_backup
                      && (
                      <button className="icon-only" type="button" onClick={() => handleNav(`https://${area.name}.web.headspin.no`)} id={area.id}>
                        <FiGlobe />
                        {' '}
                        <span className="label">{area.name}</span>
                      </button>
                      )}
                      {' '}
                      {area.name}
                    </div>
                    { !area.is_backup
                      && (
                      <div className="buttons">
                        <button className="icon-only" type="button" onClick={handleEdit} id={area.id}>
                          <FiEye />
                          {' '}
                          <span className="label">VIEW</span>
                        </button>
                      </div>
                      )}
                  </div>
                ))
              }
          </div>
        </Tabs>
        {
          (usePermissions('edit-webs') && id && areas.length > 0)
            && (
              <AddEditViewArea area={areas.find((item) => item.id === parseInt(id, 10))} />
            )
        }
      </div>
    </div>
  );
}

export default ListAreas;
