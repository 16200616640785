import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    token: '', // 1d - Final API scale
    user: '',
  },
  reducers: {
    updateSettings: (settings, action) => {
      console.log('updating settings');
      if (!action.payload) return settings; // used on first load

      const newSettings = {
        ...settings,
        token: action.payload.token,
        user: action.payload.user,
      };

      return newSettings;
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const selectSettings = (state) => state.settings;

export default settingsSlice.reducer;
