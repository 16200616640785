import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiServer, FiSettings, FiUsers, FiUser, FiLogOut,
} from 'react-icons/fi';
import { usePermissions } from '../Helpers/Helpers';

import './MainMenu.scss';

function MainMenu() {
  const logOut = (e) => {
    const clickedNav = e.target.getAttribute('nav');

    if (clickedNav === 'logout') {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
    }
  };

  return (
    <nav className="navbar">
      <NavOptions />
      <a
        className="nav-link"
        href="/"
        rel="noopener noreferrer"
        nav="logout"
        onClick={logOut}
      >
        <FiLogOut />
        Log Out
      </a>
    </nav>
  );
}

const NavOptions = () => {
  // navigation menu options
  const navOptions = [
    {
      label: 'Web Areas',
      url: '/',
      icon: <FiServer />,
    },
    {
      label: 'Profile',
      url: '/profile',
      icon: <FiUser />,
    },
    {
      label: 'Preferences',
      url: '/preferences',
      icon: <FiSettings />,
    },
  ];

  // Check if we have permissions to edit other users and inject element to menu
  if (usePermissions('view-users')) navOptions.splice(1, 0, { label: 'Users', url: '/users', icon: <FiUsers /> });

  return (
    navOptions.map((option) => (
      <NavLink
        to={option.url}
        className={(activeItem) => `nav-link${activeItem === option.url ? ' selected' : ''}`}
        key={option.label}
      >
        {option.icon}
        {option.label}
      </NavLink>
    ))
  );
};

export default MainMenu;
