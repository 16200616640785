import { useState } from 'react';

const useToken = () => {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = async (userToken) => {
    if (userToken?.token) {
      sessionStorage.setItem('user', JSON.stringify(userToken.user));
      sessionStorage.setItem('token', JSON.stringify({ token: userToken.token }));
      setToken(userToken.token);
    }
  };

  return {
    setToken: saveToken,
    token,
  };
};

export default useToken;
