import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import axios from 'axios';
// redux
import { selectSettings, updateSettings } from '../../app/settings';
// css
import './EditProfile.scss';

const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

function EditProfile() {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: settings.user,
  });
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data) => {
    setSubmitting(true);
    delete data.scopes;
    if (data.password === '') {
      delete data.password;
      delete data.password_confirmation;
    }

    const updateUser = async () => {
      const response = await client.put(
        '/self/update',
        data,
        { headers: { Authorization: `Bearer ${settings.token}` } },
      );
      if (settings?.token) {
        dispatch(updateSettings({
          ...settings,
          ...{
            user: response.data.user,
          },
        }));
      }
      setSubmitting(false);
    };
    updateUser();
  };

  return (
    <div className="edit-profile">
      <h2>Profile</h2>
      {submitting
                && (
                <div>
                  UPDATING
                </div>
                )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="id"
          type="hidden"
          {...register('id')}
        />
        <label htmlFor="name">
          <span>Name:</span>
          <input
            placeholder="First and Last names"
            name="name"
            id="name"
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <p>This field is required</p>}
        </label>
        <label htmlFor="email">
          <span>Email:</span>
          <input
            placeholder="email@server.com"
            name="email"
            id="email"
            type="email"
            {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
          />
          {errors.email && <p>A valid email is required</p>}
        </label>
        <label htmlFor="password">
          <span>New Password:</span>
          <input
            name="password"
            id="password"
            type="password"
            {...register('password')}
          />
        </label>
        <label htmlFor="password_confirmation">
          <span>Confirm Password:</span>
          <input
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            {...register('password_confirmation', {
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                },
              },
            })}
          />
          {errors.password_confirmation && (
          <p>
            {errors.password_confirmation.message}
          </p>
          )}
        </label>
        <div className="spacer align-top">
          <span>Your permissions:</span>
          <ul>
            {settings.user.scopes.map((scope) => (
              <li key={scope.scope}>{scope.name}</li>
            ))}
          </ul>
        </div>
        <button type="submit">
          <FiSave />
          {' '}
          <span className="label">UPDATE</span>
        </button>
      </form>
    </div>
  );
}

export default EditProfile;
