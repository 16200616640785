import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import axios from 'axios';
import PropTypes from 'prop-types';
import { selectSettings } from '../../app/settings';
import { usersUpdate } from '../../app/users';

import './AddEditUsers.scss';

const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

function AddEditUsers(props) {
  const {
    user,
  } = props;

  const { id } = useParams();
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const users = useSelector((state) => state.users.list);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const scopes = useMemo(() => [
    { index: 1, name: 'Edit Self' },
    { index: 2, name: 'View Self' },
    { index: 3, name: 'Delete Self' },
    { index: 4, name: 'Edit Users' },
    { index: 5, name: 'View Users' },
    { index: 6, name: 'Delete Users' },
    { index: 7, name: 'Edit Webs' },
    { index: 8, name: 'View Webs' },
    { index: 9, name: 'Delete Webs' },
    { index: 10, name: 'Edit Accesses' },
    { index: 11, name: 'View Accesses' },
    { index: 12, name: 'Delete Accesses' },
    { index: 13, name: 'Edit Alias' },
    { index: 14, name: 'View Alias' },
    { index: 15, name: 'Delete Alias' },
  ], []);

  const isAddMode = (id === 'new');

  useEffect(() => {
    let userScopes = [];
    const clone = { ...user };
    if (id) {
      // eslint-disable-next-line no-return-assign
      if (!isAddMode) user.scopes.map((scope) => userScopes = [...userScopes, `${scope.id}`]);
    }
    clone.scopes = userScopes;
    reset(clone);
  }, [id, isAddMode, reset, user]);

  const createUser = async (data) => {
    const response = await client.post(
      '/users',
      data,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    dispatch(usersUpdate([...users, response.data]));
    setSubmitting(false);
  };

  const updateUser = async (data) => {
    const response = await client.put(
      `/users/${data.id}`,
      data,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    const newUsers = users.map((obj) => {
      if (obj.id === parseInt(response.data.id, 10)) {
        return response.data;
      }
      return obj;
    });

    dispatch(usersUpdate(newUsers));
    setSubmitting(false);
  };

  function onSubmit(data) {
    setSubmitting(true);

    if (data.password === '') {
      delete data.password;
      delete data.password_confirmation;
    }
    return isAddMode
      ? createUser(data)
      : updateUser(data);
  }

  return (
    <div className="userForm">
      <h2>{isAddMode ? 'Add User' : 'Edit User'}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {user
        && (
        <input
          name="id"
          type="hidden"
          {...register('id')}
        />
        )}
        <label htmlFor="name">
          <span>Name:</span>
          <input
            placeholder="First and Last names"
            name="name"
            id="name"
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <p>This field is required</p>}
        </label>
        <label htmlFor="id">
          <span>Email:</span>
          <input
            placeholder="email@server.com"
            name="email"
            id="email"
            type="email"
            {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
          />
          {errors.email && <p>A valid email is required</p>}
        </label>
        <label htmlFor="password">
          <span>New Password:</span>
          <input
            name="password"
            type="password"
            id="password"
            {...register('password')}
          />
        </label>
        <label htmlFor="password_confirmation">
          <span>Confirm Password:</span>
          <input
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            {...register('password_confirmation', {
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                },
              },
            })}
          />
          {errors.password_confirmation && (
          <p>
            {errors.password_confirmation.message}
          </p>
          )}
        </label>
        <div className="spacer align-top">
          <span>Your permissions:</span>
          <ul>
            {scopes.map((scope) => (
              <li key={`scope_${scope.index}`}>
                <label className="check" htmlFor={`scope_${scope.index}`}>
                  <input
                    name="scopes"
                    id={`scope_${scope.index}`}
                    type="checkbox"
                    value={scope.index}
                    {...register('scopes', { required: true })}
                  />
                  <div className="checkmark" />
                  {scope.name}
                </label>
              </li>
            ))}
          </ul>
          {errors.scopes && <p>Please select at least one permission</p>}
        </div>
        <div className="spacer">
          <span> </span>
          <button type="submit">
            <FiSave />
            {' '}
            {isAddMode ? 'CREATE' : 'UPDATE'}
          </button>
          {
            submitting
              && <p>Submiting form... please wait</p>
          }
        </div>
      </form>
    </div>
  );
}

export default AddEditUsers;

AddEditUsers.defaultProps = {
  user: {},
};

AddEditUsers.propTypes = {
  user: PropTypes.object,
};
