import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from './Accordion';

import './AccordionGroup.scss';

function AccordionGroup(props) {
  const {
    children,
  } = props;

  const [activeAccordion, setActiveAccordion] = useState(children[0].props.label);

  const onClickAccordionItem = (element) => {
    setActiveAccordion(element);
  };

  return (
    <div className="accordion-group">
      {children.map((child) => {
        const { label } = child.props;
        return (
          <Accordion
            activeAccordion={activeAccordion}
            key={label}
            label={label}
            onClick={onClickAccordionItem}
            data={child.props.children}
            icon={child.props.icon}
          />
        );
      })}
    </div>
  );
}

export default AccordionGroup;

AccordionGroup.propTypes = {
  children: PropTypes.array.isRequired,
};
