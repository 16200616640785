import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const userSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    total: 0,
    loading: false,
  },
  reducers: {
    usersRequested: (users) => {
      users.loading = true;
      console.log('Requesting data');
    },

    usersReceived: (users, action) => {
      // handle and clear object here if needed
      users.list = action.payload;
      users.total = action.payload.length;
      users.loading = false;
      console.log('Data received and handled!');
    },

    usersRequestFailed: (users) => {
      users.loading = false;
      console.log('Request failed!');
    },

    usersUpdate: (users, action) => {
      users.list = action.payload;
    },

  },
});

export const {
  usersRequested,
  usersReceived,
  usersRequestFailed,
  usersUpdate,
} = userSlice.actions;

export const loadUsers = () => (dispatch) => dispatch(
  apiCallBegan({
    url: '/users',
    method: 'get',
    params: {},
    onStart: usersRequested.type,
    onSuccess: usersReceived.type,
    onError: usersRequestFailed.type,
  }),
);

export default userSlice.reducer;
