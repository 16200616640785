import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  FiSave,
  FiArchive,
  FiLock,
  FiFolder,
  FiLayers,
  FiDatabase,
  FiShield,
  FiGitlab,
  FiPlus,
} from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import AccordionGroup from './AccordionGroup';
import { areasUpdate } from '../../app/areas';

//
const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

const handleEdit = (event) => {
  const { target } = event;
  console.log(target.id);
};

function AreaEdit(props) {
  const {
    area,
  } = props;

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const areas = useSelector((state) => state.areas.list);
  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const updateArea = async (data) => {
    console.log(data);
    const response = await client.put(
      `/web/${data.id}`,
      data,
      { headers: { Authorization: `Bearer ${settings.token}` } },
    );
    const newAreas = areas.map((obj) => {
      if (obj.id === parseInt(response.data.id, 10)) {
        return response.data;
      }
      return obj;
    });
    dispatch(areasUpdate(newAreas));
    setSubmitting(false);
  };

  useEffect(() => {
    const clone = { ...area };
    reset(clone);
  }, [reset, area]);

  function onSubmit(data) {
    setSubmitting(true);
    updateArea(data);
  }

  return (
    <div>
      <h2>Edit area</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {area
          && (
          <input
            name="id"
            type="hidden"
            {...register('id')}
          />
          )}
        <AccordionGroup>
          <div label="Web Area Details" icon={<FiArchive />}>
            <p className="spacer">
              <span>Area name:</span>
              {' '}
              {area.name}
            </p>
            <label htmlFor="notes" className="align-top">
              <span>Notes:</span>
              <textarea
                placeholder="notes"
                name="notes"
                id="notes"
                {...register('notes', { required: true })}
              />
              {errors.notes && <p>This field is required</p>}
            </label>
            <label className="check" htmlFor="is_support">
              <span>Support & Service:</span>
              <input
                name="is_support"
                id="is_support"
                type="checkbox"
                {...register('is_support')}
              />
              <div className="checkmark" />
            </label>
          </div>
          <div label="Access Credentials" icon={<FiLock />}>
            <p className="spacer">
              <span>Username:</span>
              {area.name}
            </p>
            <p className="spacer">
              <span>Password:</span>
              {area.code}
            </p>
          </div>
          <div label="Database Access" icon={<FiDatabase />}>
            <p className="spacer">
              <span>DB Host:</span>
              {' '}
              172.16.6.33
            </p>
            <p className="spacer">
              <span>DB Name:</span>
              {area.name}
            </p>

          </div>
          <div label="File System Access" icon={<FiFolder />}>
            <p>Direct file access should be avoided.</p>
            <em>
              Access is exclusive via SFTP (SSH File Transfer Protocol)
            </em>
            <p className="spacer">
              <span>SFTP Host:</span>
              {' '}
              web.headspin.no
            </p>
          </div>
          <div label="Aliases" icon={<FiLayers />}>
            {
              (area.alias.length > 0)
                ? area.alias.map((alias) => (
                  <p>
                    https://
                    {alias.name}
                    .web.headspin.no
                  </p>
                )) : (
                  <p>No aliases found</p>
                )
            }
            <button type="button" onClick={handleEdit} id="new">
              <FiPlus />
              {' '}
              ADD NEW
            </button>
          </div>
          <div label="Security" icon={<FiShield />}>
            <p>Select options:</p>
            <label className="check" htmlFor="is_cors">
              <span>Enable CORS</span>
              <input
                name="is_cors"
                id="is_cors"
                type="checkbox"
                {...register('is_cors')}
              />
              <div className="checkmark" />
            </label>
            <label className="check" htmlFor="is_private">
              <span>Make private</span>
              <input
                name="is_private"
                id="is_private"
                type="checkbox"
                {...register('is_private')}
              />
              <div className="checkmark" />
            </label>
            <label className="check" htmlFor="is_protected">
              <span>Protected</span>
              <input
                name="is_protected"
                id="is_protected"
                type="checkbox"
                {...register('is_protected')}
              />
              <div className="checkmark" />
            </label>
            <label className="check" htmlFor="is_public">
              <span>Make Public</span>
              <input
                name="is_public"
                id="is_public"
                type="checkbox"
                {...register('is_public')}
              />
              <div className="checkmark" />
            </label>
            {
            area.access.length > 0
            && (
            <div>
              <p className="spacer align-top">
                <span>HTTP credentials:</span>
                <ul className="credentials">
                  <li>
                    <span>Username</span>
                    <span>Password</span>
                  </li>
                  {
                    area.access.map((access) => (
                      <li>
                        <span>{access.username}</span>
                        <span>{access.password}</span>
                      </li>
                    ))
                  }
                </ul>
              </p>
            </div>
            )
            }
          </div>
          <div label="Version Control" icon={<FiGitlab />}>
            <p className="spacer align-top">
              <span>Git Hook:</span>
              {' '}
              {area.git_url ? `https://${area.name}.web.headspin.no/git-pull.php` : 'No'}
            </p>
            <label htmlFor="git_url">
              <span>GIT repo:</span>
              <input
                placeholder="git_url"
                name="git_url"
                id="git_url"
                type="text"
                {...register('git_url')}
              />
              <div className="checkmark" />
            </label>
          </div>
        </AccordionGroup>
        <div className="spacer">
          <span> </span>
          <button type="submit">
            <FiSave />
            {' '}
            UPDATE
          </button>
          {
          submitting
            && <p>Submiting form... please wait</p>
          }
        </div>
      </form>
    </div>
  );
}
export default AreaEdit;

AreaEdit.propTypes = {
  area: PropTypes.object.isRequired,
};
