import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FiArrowRight } from 'react-icons/fi';
import './ForgotPassword.scss';

const client = axios.create({
  baseURL: 'https://api.web.headspin.no/api',
});

async function requestPin(credentials) {
  return client.post('/forgot-password', credentials).then((response) => response.data);
}

function ForgotPassword() {
  const [submitting, setSubmitting] = useState(false);
  const [reset, setReset] = useState({});
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setSubmitting(true);
    const requestPassword = async () => {
      const resetPassword = await requestPin({
        email: data.email,
      });
      setReset(resetPassword);
      setSubmitting(false);
    };
    requestPassword();
  };

  // console.log(reset);
  return (
    <div className="panel-wrapper">
      {submitting
          && <div>Logging in... Please wait...</div>}
      {
            submitting
                && <div>Requesting password pin...</div>
        }
      {
            reset.success
                && <Navigate to="/reset-password" />
        }
      <h2>Password recovery</h2>
      <p>Please insert the email address you use to login.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="email">
          <span>Username:</span>
          <input
            type="text"
            name="email"
            id="email"
            {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
          />
          {errors.email && <p>A valid email is required</p>}
        </label>
        <div className="spacer">
          <span> </span>
          <Link to="/" id="link">Return to Login</Link>
        </div>
        <div className="spacer">
          <span> </span>
          <button type="submit">
            <FiArrowRight />
            {' '}
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
