import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import axios from 'axios';

import './ResetPassword.scss';

const client = axios.create({
  baseURL: 'https://api.web2.headspin.no/api',
});

function ResetPassword() {
  const {
    register, handleSubmit, getValues, formState: { errors },
  } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data) => {
    setSubmitting(true);

    const updatePassword = async () => {
      const response = await client.post(
        '/reset-password',
        data,
      );
      console.log(response.data);
      setSubmitting(false);
    };
    updatePassword();
    setSubmitting(false);
  };

  return (
    <div className="panel-wrapper">
      {
        submitting
            && <div>Requesting password pin...</div>
      }
      <h2>Set new Password</h2>
      <div>
        <p>Please check your email for the required 6 digit pin</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            <span>Email:</span>
            <input
              placeholder="email@server.com"
              name="email"
              type="email"
              id="email"
              {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
            />
            {errors.email && <p>A valid email is required</p>}
          </label>
          <label htmlFor="password">
            <span>New Password:</span>
            <input
              placeholder="XXXXXX"
              name="password"
              type="password"
              id="password"
              {...register('password', { required: true })}
            />
          </label>
          <label htmlFor="password_confirmation">
            <span>Confirm Password:</span>
            <input
              placeholder="XXXXXX"
              name="password_confirmation"
              type="password"
              id="password_confirmation"
              {...register('password_confirmation', {
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || 'Passwords should match!';
                  },
                },
              })}
            />
            {errors.password_confirmation && (
              <p>
                {errors.password_confirmation.message}
              </p>
            )}
          </label>

          <label htmlFor="token">
            <span>PIN:</span>
            <input
              placeholder="XXXXXX"
              name="token"
              type="text"
              id="token"
              {...register('token', { required: true })}
            />
            {errors.token && <p>A valid PIN is required</p>}
          </label>
          <div className="spacer">
            <span> </span>
            <Link to="/" id="link">Return to Login</Link>
          </div>
          <div className="spacer">
            <span> </span>
            <button type="submit">
              <FiArrowRight />
              {' '}
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
